<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="notive">
		<div class="notive-content">
			<div class="notive-table">
				<el-table :data="tableData" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<!-- <el-table-column width="200px" prop="type" label="账号头像">
						<template slot-scope="scope">
							<img class="action-avatar" v-if="userInfo.avatarUrl" :src="userInfo.avatarUrl" alt="" />
							<img class="action-avatar" v-else src="@/assets/images/common/avatar.png" alt="" />
						</template>
					</el-table-column> -->
					<el-table-column width="200px" prop="userRealname" label="账号名称">
					</el-table-column>
					<!-- <el-table-column prop="index" label="所属组织">
						<template slot-scope="scope">
							<span>{{userInfo.orgShortName}}</span>
						</template>
					</el-table-column> -->
					<el-table-column prop="operation" label="操作事项">
					</el-table-column>
					<el-table-column width="100px" prop="statusCode" label="状态">
						<template slot-scope="scope">
							<span>{{scope.row.statusCode == 0?'成功': "失败"}}</span>
						</template>
					</el-table-column>
					<el-table-column width="250px" prop="createTime" label="操作时间">
					</el-table-column>
					<!-- <el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('edit', scope)">编辑</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="actionFn('delete', scope)">删除</span>
							</div>
						</template>
					</el-table-column> -->
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listHandleLog
	} from "@/api/system";
	import storage from 'store';
	export default {
		components: {},
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				userInfo: {},
			};
		},
		watch: {},
		mounted() {
			this.userInfo = storage.get('ACCESS_Info')
			this.getHandleLogList();
		},
		methods: {
			actionFn(type, scope) {
				switch (type) {
					case "edit":
						this.$router.push({
							path: "/noticeManage/detail"
						})
						break;
				}
			},
			getHandleLogList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				}
				listHandleLog(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getHandleLogList();
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #DCDFE6;
	}

	.notive {
		.notive-content {
			padding: 0 20px 20px 20px;

			.notive-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.notive-table {
				margin-top: 20px;
			}
		}

	}
	
	.action-avatar {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}
</style>
